<template>
  <div class="coupon-content">
    <div class="headnav"><i class="icon" @click="$router.go(-1)"></i>Zero Risk Bets</div>
    <div class="banner">
      <van-swipe class="swipe" :autoplay="3000">
        <van-swipe-item>
          <img src="../assets/bannert2.png"/>
        </van-swipe-item>
      </van-swipe>
      <div class="bannerBtn">Check Details &gt;</div>
    </div>
    <div class="coupon">
      <div class="title">If lose KSh 20, KSh 20 cash coupon will be refunded to your account instantly</div>
      <div class="progress">
        <div class="main">
          <div class="line">
            <div class="slider" v-bind:style="'width:'+progress+'%'"></div>
          </div>
          <div :class="item.left<=progress?'item active':'item'" v-for="(item, index) in list" :key="index" v-bind:style="'left:'+item.left+'%'">
            <div class="top">{{item.money}}</div>
            <div class="icon"><img src="../assets/icon/icon-coupon.png"></div>
            <div class="num">x{{item.num}}</div>
          </div>
        </div>
      </div>
      <div class="couponlist">
        <div :class="item.isuse=='0'?'item active':'item'" v-for="(item, index) in couponlist" :key="index">
          <div class="top">
            <div class="unit">KSH</div>
            <div class="money">{{item.money/100}}</div>
            <div class="other">
              <div class="expired" v-if="item.isuse=='0'">Expired</div>
              <div class="date" v-if="item.isuse=='0'">{{purchase_data_str(item.gtime+'000')}}</div>
            </div>
          </div>
          <div class="bottom" @click="$router.push('/')">
            <span v-if="item.isuse=='0'">Use Now</span>
            <span v-else-if="item.isuse=='1'">Used</span>
            <span v-else-if="item.isuse=='2'">Expire</span>
            <span v-else>Claim Now</span>
          </div>
        </div>
      </div>
    </div>
    <div class="rule">
      <div class="title">Rules</div>
      <div class="desc">
        <div>1. This zero risk bets offer is only for Shinda 3.</div>
        <div>2. This lose and refund table is as follow:</div>
      </div>
      <div>
        <table class="table">
          <thead>
          <tr class="title-th">
            <th class="a">Tier</th>
            <th class="b">If Lose</th>
            <th class="c">Refunds</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(info, index) in ruleLit" :key="index">
            <td class="a">{{info.tier}}</td>
            <td class="b">{{info.lose}}</td>
            <td class="c">{{info.refunds}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
    import {serve} from "../constant";
    import {formatDate} from "../utils/date";

    export default {
        data() {
            return {
                activeName: "",
                curPage: 1,
                limit: 10,
                maxPage: 0,
                progress: 0,
                list: [
                  {id:1, left: 10, money: 20, num: 1},
                  {id:1, left: 20, money: 40, num: 1},
                  {id:1, left: 30, money: 60, num: 1},
                  {id:1, left: 40, money: 80, num: 1},
                  {id:1, left: 60, money: 120, num: 2},
                  {id:1, left: 80, money: 160, num: 2},
                  {id:1, left: 100, money: 200, num: 2},
                ],
                couponlist:[],
                total: 0,
                ruleLit:[
                  {tier: 1, lose: "KSH 20", refunds: "KSH 20"},
                  {tier: 2, lose: "KSH 40", refunds: "KSH 40"},
                  {tier: 3, lose: "KSH 60", refunds: "KSH 60"},
                  {tier: 4, lose: "KSH 100", refunds: "KSH 80"},
                  {tier: 5, lose: "KSH 150", refunds: "KSH 120"},
                  {tier: 6, lose: "KSH 200", refunds: "KSH 160"},
                  {tier: 7, lose: "KSH 300", refunds: "KSH 200"},
                ]
            }
        },
        created() {
            this.requestCoupon();
        },
        watch: {
            '$route' () {
              this.requestCoupon();
            }
        },
        computed: {

        },
        methods: {
          requestCoupon() {
            this.$store.dispatch('serve_req', {
                url: serve.user.myCoupon,
                method: 'get',
                data: {}
            }).then((data) => {
              this.couponlist = [];
              this.total = data.lj;
              let progress = data.lj/200*100;
              if(progress>100){
                progress = 100;
              }
              this.progress = progress;
              let rewardTicketArray = data.rewardTicketArray;
              if(rewardTicketArray){
                let couponlist = JSON.parse(rewardTicketArray);
                for(let i in couponlist){
                  this.couponlist.unshift(couponlist[i]);
                }
              }
            })
          },
          purchase_data_str(timestamp) {
              return formatDate(new Date(timestamp*1), 'yyyy-MM-dd hh:mm');
          },
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";
  .coupon-content{
    font-family: Ubuntu;
    margin-top: 104px;
    .banner {
      .bannerBtn{
        position: absolute;
        top: 40px;
        right: 20px;
        background-color: #008250;
        border-radius: 20px;
        color: #FFF;
        padding: 10px 20px;
        font-size: 20px;
      }
      /deep/ .swipe {
        .van-swipe-item {
          font-size: 0;
          img {
            width: 100%;
          }
        }

        .van-swipe__indicators {
          left: auto;
          right: 0;
          text-align: right;
        }

        .van-swipe__indicator {
          width: 13px;
          height: 13px;
          background-color: #FFFFFF;
          opacity: 0.5;
          border-radius: 50%;
          margin-left: 12px;
        }

        .van-swipe__indicator--active {
          background-color: #FFFFFF;
          opacity: 1;
        }
      }
    }
    .coupon{
      padding: 20px;
      .title{
        font-family: Ubuntu;
        color: #373737;
        font-size: 28px;
        text-align: left;
      }
      .progress{
        margin-top: 100px;
        padding: 0 40px;
        .main{
          position: relative;
        }
        .line{
          width: 100%;
          height: 8px;
          border-radius: 4px;
          background-color: #EBEBEB;
          position: relative;
          .slider{
            position: absolute;
            top: 0;
            left: 0;
            width: 10%;
            height: 100%;
            background-color: #008250;
            border-radius: 4px;
          }
        }
        .item{
          position: absolute;
          top: -65px;
          left: 10%;
          font-size: 22px;
          margin-left: -24px;
          .top{
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 50%;
            background-color: #EBEBEB;
            color: #737373;
            font-weight: bold;
            position: relative;
            &::before{
              content: "";
              position: absolute;
              bottom: -23px;
              left: 50%;
              margin-left: -15px;
              border-width: 15px;
              border-style: solid dashed dashed;
              border-color: #EBEBEB transparent transparent;
            }
          }
          .icon{
            margin-top: 34px;
            img{
              width: 40px;
              height: 40px;
            }
          }
          .num{
            color: #008250;
            font-size: 18px;
          }
        }
        .active{
          .top{
            color: #fff;
            background-color: #F55000;
            &::before{
              border-color: #F55000 transparent transparent;
            }
          }
        }
      }

      .couponlist{
        margin-top: 120px;
        text-align: left;
        .item{
          width: 30%;
          font-size: 20px;
          display: inline-block;
          margin: 11px;
          text-align: center;
          .top{
            background-color: #EBEBEB;
            border-radius: 24px 24px 0 0;
            position: relative;
            padding: 10px;
            padding-top: 100px;
            .unit{
              position: absolute;
              top: 30px;
              left: 40px;
              color: #AFAFAF;
              font-size: 24px;
            }
            .money{
              position: absolute;
              top: 20px;
              left: 96px;
              color: #AFAFAF;
              font-size: 64px;
            }
            .other{
              height: 60px;
            }
            .expired{

            }
            .date{

            }
          }
          .bottom{
            color: #FFF;
            border-radius: 0 0 24px 24px;
            background-color: #008250;
            padding: 14px 0;
            font-size: 24px;
          }
        }
        .active{
          .top{
            background-color: #FFEBC8;
            .unit{
              color: #F55000;
            }
            .money{
              color: #F55000;
            }
          }
          .bottom{
            background-color: #F55000;
          }
        }
      }


    }
    .rule{
      font-size: 24px;
      padding: 20px;
      text-align: left;
      .title{
        color: #373737;
        font-weight: bold;
        font-size: 28px;
      }
      .desc{
        color: #737373;
        font-size: 28px;
        margin-top: 10px;
      }
      .table {
        border-spacing: 0;
        border-collapse: collapse;
        margin: 20px 0;
        border-radius: 24px;
        overflow: hidden;
        text-align: center;
        tr {
          height: 86px;
          border-bottom: 1px solid #E1E1E1;
          background-color: #F5F5F5;
          &:last-child{
            border-bottom: 0;
          }
        }
        th {
          padding-left: 15px;
        }
        td {
          height: 25px;
          font-size: 22px;
          font-family: ArialMT;
          color: #333333;
          line-height: 26px;
        }
        .title-th {
          height: 86px;
          background: #EBEBEB;
          font-size: 22px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #737373;
          line-height: 26px;
          text-align: center;
          border: none;
        }
        .a {
          width: 130px;
        }
        .b {
          width: 300px;
        }
        .c {
          width: 300px;
        }
      }
    }
  }
</style>
